/* 1024++  */
.b-footer {
    &__phone {
        font-size: 28px;
        line-height: 28px;
        letter-spacing: 1px;
    }

    &__link {
        font-size: 14px;
        line-height: 16px;
    }
}