/* 1024++  */
.b-expert {
    &__dots {
        display: none;
    }

    &__wrapper {
        padding-top: 40px;
    }

    &__title {
        font-size: 48px;
        line-height: 40px;
        font-weight: 400;
        letter-spacing: 14px;
        top: 30px;
    }

    &__name {
        font-size: 30px;
        line-height: 28px;
        margin-bottom: 30px;
    }

    &__content {
        line-height: 22px;

        p {
            margin-bottom: 15px;
        }
    }

    &__description {
        padding-top: 120px;
        padding-bottom: 80px;
    }

    &__button {
        font-size: 16px;
        height: 48px;
        width: 200px;
    }

    &__arrow-group {
        .slick-arrow {
            span {
                font-size: 50px;
            }
        }
    }
}