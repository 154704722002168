/* 1024++  */
.b-about {
    &__panel {
        padding-left: 350px;
    }

    &__image {
        left: -200px;
        top: 90px;

        img {
            width: 530px;
            height: auto;
        }
    }

    &__banner {
        right: 50px;
    }

    &__link {
        padding-left: 0;
    }

    &__icon {
        width: 70px;
    }

    &__bold {
        font-size: 70px;
        line-height: 70px;
    }

    &__number {
        font-size: 18px;
        line-height: 18px;
    }

    &__text {
        span {
            font-size: 22px;
        }
    }

    &__sign {
        width: 40px;
        height: 30px;

        &:after,
        &:before {
            height: 10px;
        }
    }

    &__day {
        font-size: 23px;
        line-height: 25px;

        span {
            font-size: 23px;
        }
    }

    &__new {
        font-size: 23px;
        line-height: 25px;

        span {
            font-size: 23px;
            line-height: 25px;
        }
    }

    &__item {
        padding: 20px 0;

        &:after {
            width: 100%;
        }
    }
}