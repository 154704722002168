/* 1024++  */
.b-media {
	&__panel-top {
		padding-left: 70px;
	}

	&__back {
		top: 4px;
		.text {
			display: none;
		}

		.icon-arrow-left {
			font-size: 50px;
		}
	}
}