/* 1024++  */
.b-buy-ticket {
    padding: 50px 0;

    &__group {
        margin-bottom: 70px;
    }

    &__link-group {
        span {
            font-size: 50px;
            line-height: 50px;
            letter-spacing: 0;
        }
    }

    &__list {
        margin-bottom: 50px;
    }

    &__item {
        &:before {
            top: 12px;
        }
    }

    &__title {
        font-size: 60px;
        line-height: 44px;
    }

    &__tab-pane {
        margin-bottom: 18px;
        font-size: 18px;
        line-height: 22px;
    }

    &__button {
        margin: 0 auto;
    }
}