/* 1024++  */
.b-info {
	&__group {
		font-size: 40px;
		line-height: 42px;
	}

	&__date {
		width: 380px;
	}

	&__caption {
		font-size: 18px;
		line-height: 20px;
	}

	&__item {
		width: 370px;
	}

	&__text {
		font-size: 20px;
		letter-spacing: 0.2px;
		line-height: 29px;
	}
}