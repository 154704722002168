/* 1024++  */
.b-how-pass {
    &__title {
        font-size: 50px;
        line-height: 40px;
        font-weight: 200;
        letter-spacing: 7px;
    }

    &__link {
        font-size: 12px;

        span {
            padding-top: 0;
        }
    }

    &__list {
        justify-content: center;
    }

    &__item {
        &:first-child {
            margin-right: 40px;
        }
    }
}